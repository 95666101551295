<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <h5>权限类型</h5>
        <TabView v-model:activeIndex="activeIndex" @tab-click="tabViewClick">
            <TabPanel header="全部员工">
                <TreeTable :key="key" :value="allUserPermission" selectionMode="single"
                    v-model:selectionKeys="allUserPermissionSelectedKey" :paginator="true" :rows="50" :loading="loading"
                    :totalRecords="totalRecords" :first="first" :scrollable="true" :scrollHeight="sHeight"
                    scrollDirection="vertical"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}"
                    @page="allUserPermissionListPage($event)">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <InputText id='objnameInput' placeholder="对象名称" v-model="objnameSearch"
                                        type="text" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button label="查询" icon="pi pi-search" @click="loadAllUserPermissionList(1)"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="新建" icon="pi pi-plus" @click="createallUserPermission()"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="删除" icon="pi pi-times" @click="delallUserPermission()"
                                        class="button-danger" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="objname" header="对象名称" style="width: 45%" :expander="true" frozen>
                        <template #body="slotProps">
                            <Button v-if="slotProps.node.data['objtype']=='obj'" :label="slotProps.node.data['objname']"
                                @click="openAllUserPermissiondetail(slotProps.node)" class="p-button-link" />
                            <label
                                v-if="slotProps.node.data['objtype']!='obj'">{{slotProps.node.data['objname']}}</label>
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="width: 25%">
                    </Column>
                    <Column field="canquery" header="可见" style="width: 15%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canquery'])}}
                        </template>
                    </Column>
                    <Column field="canmutation" header="可编辑" style="width: 15%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canmutation'])}}
                        </template>
                    </Column>
                </TreeTable>
            </TabPanel>
            <TabPanel header="AppId">
                <TreeTable :key="key" :value="appIdPermission" selectionMode="single"
                    v-model:selectionKeys="appIdPermissionSelectedKey" :paginator="true" :loading="loading" :rows="50"
                    :totalRecords="totalRecords" :first="first" :scrollable="true" :scrollHeight="sHeight"
                    scrollDirection="both"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}"
                    @page="appIdPermissionListPage($event)">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <InputText id='objnameInput2' placeholder="对象名称" v-model="objnameSearch"
                                        type="text" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Dropdown id="appidDropdown" style="width:300px" v-model="selectedAppId"
                                        :options="allAppId" placeholder="AppId" optionLabel="name" optionValue="code"
                                        showClear="true" :filter="true" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button label="查询" icon="pi pi-search" @click="loadAppIdPermissionList(1)"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="新建" icon="pi pi-plus" @click="createappIdPermission()"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="删除" icon="pi pi-times" @click="delappIdPermission()"
                                        class="button-danger" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="objname" header="对象名称" style="width: 20%" :expander="true" frozen>
                        <template #body="slotProps">
                            <Button v-if="slotProps.node.data['objtype']=='obj'" :label="slotProps.node.data['objname']"
                                @click="openAppIdPermissiondetail(slotProps.node)" class="p-button-link" />
                            <label
                                v-if="slotProps.node.data['objtype']!='obj'">{{slotProps.node.data['objname']}}</label>
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="width: 18%">
                    </Column>
                    <Column field="appid" header="AppId" style="width: 20%">
                    </Column>
                    <Column field="appidcname" header="描述" style="width: 20%">
                    </Column>
                    <Column field="canquery" header="可见" style="width: 10%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canquery'])}}
                        </template>
                    </Column>
                    <Column field="canmutation" header="可编辑" style="width: 12%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canmutation'])}}
                        </template>
                    </Column>
                </TreeTable>
            </TabPanel>
            <TabPanel header="部分员工">
                <TreeTable :key="key" :value="partUserPermission" selectionMode="single" :loading="loading"
                    v-model:selectionKeys="partUserPermissionSelectedKey" :paginator="true" :rows="50"
                    :totalRecords="totalRecords" :first="first" :scrollable="true" :scrollHeight="sHeight"
                    scrollDirection="vertical"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}"
                    @page="partUserPermissionListPage($event)">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <InputText id='objnameInput3' placeholder="对象名称" v-model="objnameSearch"
                                        type="text" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <CalfInputDropdown placeholder="员工姓名" v-model:selectedValue="selectedPartUserId"
                                        v-model:selectedTxt="selectedPartUserName" id="partUserDropdown"
                                        dictObjName="userdict" :loadAll="true" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button label="查询" icon="pi pi-search" @click="loadPartUserPermissionList(1)"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="新建" icon="pi pi-plus" @click="createpartUserPermission()"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="删除" icon="pi pi-times" @click="delpartUserPermission()"
                                        class="button-danger" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="objname" header="对象名称" style="width: 30%" :expander="true" frozen>
                        <template #body="slotProps">
                            <Button v-if="slotProps.node.data['objtype']=='obj'" :label="slotProps.node.data['objname']"
                                @click="openPartUserPermissiondetail(slotProps.node)" class="p-button-link" />
                            <label
                                v-if="slotProps.node.data['objtype']!='obj'">{{slotProps.node.data['objname']}}</label>
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="width: 18%">
                    </Column>
                    <Column field="username" header="人员姓名" style="width: 30%">
                    </Column>
                    <Column field="canquery" header="可见" style="width: 10%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canquery'])}}
                        </template>
                    </Column>
                    <Column field="canmutation" header="可编辑" style="width: 12%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canmutation'])}}
                        </template>
                    </Column>
                </TreeTable>
            </TabPanel>
            <TabPanel header="管理人员">
                <TreeTable :key="key" :value="managerPermission" selectionMode="single" :loading="loading"
                    v-model:selectionKeys="managerPermissionSelectedKey" :paginator="true" :rows="50"
                    :totalRecords="totalRecords" :first="first" :scrollable="true" :scrollHeight="sHeight"
                    scrollDirection="both"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}"
                    @page="managerPermissionListPage($event)">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <InputText id='objnameInput4' placeholder="对象名称" v-model="objnameSearch"
                                        type="text" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <CalfInputDropdown placeholder="员工姓名" v-model:selectedValue="selectedManagerUserId"
                                        v-model:selectedTxt="selectedManagerUserName" id="managerDropdown"
                                        dictObjName="userdict" :loadAll="true" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button label="查询" icon="pi pi-search" @click="loadManagerPermissionList(1)"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="新建" icon="pi pi-plus" @click="createManagerPermission()"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="删除" icon="pi pi-times" @click="delManagerPermission()"
                                        class="button-danger" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="objname" header="对象名称" style="width:16%" :expander="true" frozen>
                        <template #body="slotProps">
                            <Button v-if="slotProps.node.data['objtype']=='obj'" :label="slotProps.node.data['objname']"
                                @click="openManagerPermissiondetail(slotProps.node)" class="p-button-link" />
                            <label
                                v-if="slotProps.node.data['objtype']!='obj'">{{slotProps.node.data['objname']}}</label>
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="width: 10%">
                    </Column>
                    <Column field="username" header="人员姓名" style="width:8%">
                    </Column>
                    <Column field="manageuser" header="下属" style="width:20%">
                        <template #body="slotProps">
                            <div class="grid">
                                <div class="col-4" v-for="uitem of slotProps.node.data['manageuser']" :key="uitem.id">
                                    <Tag class="mr-2" severity="info" :value="uitem.name"></Tag>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="manageorganization" header="管理部门" style="width:30%">
                        <template #body="slotProps">
                            <div class="grid">
                                <div class="col-12" v-for="uitem of slotProps.node.data['manageorganization']"
                                    :key="uitem.id">
                                    <Tag class="mr-2" severity="info" :value="uitem.name"></Tag>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="canquery" header="可见" style="width:8%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canquery'])}}
                        </template>
                    </Column>
                    <Column field="canmutation" header="可编辑" style="width:8%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canmutation'])}}
                        </template>
                    </Column>
                </TreeTable>
            </TabPanel>
            <TabPanel header="流程">
                <TreeTable :key="key" :value="flowPermission" selectionMode="single" :loading="loading"
                    v-model:selectionKeys="flowPermissionSelectedKey" :paginator="true" :rows="50"
                    :totalRecords="totalRecords" :first="first" :scrollable="true" :scrollHeight="sHeight"
                    scrollDirection="both"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}"
                    @page="flowPermissionListPage($event)">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <InputText id='objnameInput5' placeholder="对象名称" v-model="objnameSearch"
                                        type="text" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Dropdown id="flowDropdown" placeholder="流程" v-model="selectedFlow"
                                        :options="flowOptions" optionLabel="name" optionValue="code" showClear="true"
                                        @change="flowDropdownchange" :filter="true" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Dropdown id="flowStateDropdown" placeholder="节点" v-model="selectedFlowState"
                                        :options="linkFlowStateOptions" optionValue="code" optionLabel="name"
                                        showClear="true" :filter="true" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button label="查询" icon="pi pi-search" @click="loadFlowPermissionList(1)"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="新建" icon="pi pi-plus" @click="createFlowPermission()"
                                        class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button label="删除" icon="pi pi-times" @click="delFlowPermission()"
                                        class="button-danger" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="objname" header="对象名称" style="width: 20%" :expander="true" frozen>
                        <template #body="slotProps">
                            <Button v-if="slotProps.node.data['objtype']=='obj'" :label="slotProps.node.data['objname']"
                                @click="openFlowPermissiondetail(slotProps.node)" class="p-button-link" />
                            <label
                                v-if="slotProps.node.data['objtype']!='obj'">{{slotProps.node.data['objname']}}</label>
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="width: 20%">
                    </Column>
                    <Column field="flowname" header="流程" style="width: 20%">
                    </Column>
                    <Column field="statename" header="节点" style="width: 20%">
                    </Column>
                    <Column field="canquery" header="可见" style="width: 10%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canquery'])}}
                        </template>
                    </Column>
                    <Column field="canmutation" header="可编辑" style="width: 10%">
                        <template #body="slotProps">
                            {{formatData('bool',slotProps.node.data['canmutation'])}}
                        </template>
                    </Column>
                </TreeTable>
            </TabPanel>
        </TabView>
    </div>
    <Dialog header="全部员工权限设置详细" v-model:visible="allUserPermissionDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="field">
                <label for="objnameDropdown">对象名称</label>
                <Dropdown id="objnameDropdown" v-model="allUserPermissionObj.objname" :options="allSchemaName"
                    @change="allUserPermissionObjDropchange" optionLabel="name" optionValue="code" showClear="true"
                    :filter="true" />
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="canqueryDropdown">是否可见</label>
                    <Dropdown id='canqueryDropdown' v-model="allUserPermissionObj.canquery" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
                <div class="field col">
                    <label for="canmutationDropdown">是否可编辑</label>
                    <Dropdown id='canmutationDropdown' v-model="allUserPermissionObj.canmutation" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
            </div>
            <h5>属性信息</h5>
            <div class="field">
                <TreeTable :key="key" :filters="filters1" filterMode="strict" :value="allUserPermissionObj.fields"
                    showGridlines :scrollable="false" scrollDirection="both" @contextmenu="allUserPermissionRightClick"
                    responsiveLayout="scroll">
                    <Column field="objname" header="属性名称" style="flex-grow:1; flex-basis:200px" :expander="true" frozen>
                        <template #filter>
                            <InputText type="text" v-model="filters1['objname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="flex-grow:1; flex-basis:200px">
                        <template #filter>
                            <InputText type="text" v-model="filters1['objcname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="canquery" header="是否可见" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters1['canquery']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canquery']" :options="boolDict" optionLabel="name"
                                optionValue="code" placeholder="" />
                        </template>
                    </Column>
                    <Column field="canmutation" header="是否可编辑" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters1['canmutation']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canmutation']" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" />
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeAllUserPermissionDialog" />
                <Button label="刷新" icon="pi pi-refresh"
                    @click="loadSchemaField(allUserPermissionObj.objname, 'allUserPermission')" />
                <Button label="保存" icon="pi pi-check" @click="saveAllUserPermission" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="AppId权限设置详细" v-model:visible="appIdPermissionDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="objnameDropdownappId">对象名称</label>
                    <Dropdown id="objnameDropdownappId" v-model="appIdPermissionObj.objname" :options="allSchemaName"
                        @change="appIdPermissionObjDropchange" optionLabel="name" optionValue="code" showClear="true"
                        :filter="true" />
                </div>
                <div class="field col">
                    <label for="appIdDropdown2">AppId</label>
                    <Dropdown id='appIdDropdown2' v-model="appIdPermissionObj.appid" :options="allAppId"
                        optionLabel="name" optionValue="code" placeholder="" showClear="true" :filter="true" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="canqueryDropdownappId">是否可见</label>
                    <Dropdown id='canqueryDropdownappId' v-model="appIdPermissionObj.canquery" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
                <div class="field col">
                    <label for="canmutationDropdownappId">是否可编辑</label>
                    <Dropdown id='canmutationDropdownappId' v-model="appIdPermissionObj.canmutation" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
            </div>
            <h5>属性信息</h5>
            <div class="field">
                <TreeTable :key="key" :filters="filters2" filterMode="strict" :value="appIdPermissionObj.fields"
                    showGridlines :scrollable="false" scrollDirection="both" @contextmenu="appIdPermissionRightClick"
                    responsiveLayout="scroll">
                    <Column field="objname" header="属性名称" style="flex-grow:1; flex-basis:200px" :expander="true" frozen>
                        <template #filter>
                            <InputText type="text" v-model="filters2['objname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="flex-grow:1; flex-basis:200px">
                        <template #filter>
                            <InputText type="text" v-model="filters2['objcname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="canquery" header="是否可见" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters2['canquery']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canquery']" :options="boolDict" optionLabel="name"
                                optionValue="code" placeholder="" />
                        </template>
                    </Column>
                    <Column field="canmutation" header="是否可编辑" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters2['canmutation']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canmutation']" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" />
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeAppIdPermissionDialog" />
                <Button label="刷新" icon="pi pi-refresh"
                    @click="loadSchemaField(appIdPermissionObj.objname, 'appIdPermission')" />
                <Button label="保存" icon="pi pi-check" @click="saveAppIdPermission" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="部分员工权限设置详细" v-model:visible="partUserPermissionDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="objnameDropdownpartUser">对象名称</label>
                    <Dropdown id="objnameDropdownpartUser" v-model="partUserPermissionObj.objname"
                        :options="allSchemaName" @change="partUserPermissionObjDropchange" optionLabel="name"
                        optionValue="code" showClear="true" :filter="true" />
                </div>
                <div class="field col">
                    <label for="partUserDropdown2">用户名</label>
                    <CalfInputDropdown v-model:selectedValue="partUserPermissionObj.userid"
                        v-model:selectedTxt="partUserPermissionObj.username" id="partUserDropdown2"
                        dictObjName="userdict" :loadAll="true" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="canqueryDropdownpartUser">是否可见</label>
                    <Dropdown id='canqueryDropdownpartUser' v-model="partUserPermissionObj.canquery" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
                <div class="field col">
                    <label for="canmutationDropdownpartUser">是否可编辑</label>
                    <Dropdown id='canmutationDropdownpartUser' v-model="partUserPermissionObj.canmutation"
                        :options="boolDict" optionLabel="name" optionValue="code" placeholder="" />
                </div>
            </div>
            <h5>属性信息</h5>
            <div class="field">
                <TreeTable :key="key" :filters="filters3" filterMode="strict" :value="partUserPermissionObj.fields"
                    showGridlines :scrollable="false" scrollDirection="both" @contextmenu="partUserPermissionRightClick"
                    responsiveLayout="scroll">
                    <Column field="objname" header="属性名称" style="flex-grow:1; flex-basis:200px" :expander="true" frozen>
                        <template #filter>
                            <InputText type="text" v-model="filters3['objname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="flex-grow:1; flex-basis:200px">
                        <template #filter>
                            <InputText type="text" v-model="filters3['objcname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="canquery" header="是否可见" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters3['canquery']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canquery']" :options="boolDict" optionLabel="name"
                                optionValue="code" placeholder="" />
                        </template>
                    </Column>
                    <Column field="canmutation" header="是否可编辑" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters3['canmutation']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canmutation']" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" />
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closePartUserPermissionDialog" />
                <Button label="刷新" icon="pi pi-refresh"
                    @click="loadSchemaField(partUserPermissionObj.objname, 'partUserPermission')" />
                <Button label="保存" icon="pi pi-check" @click="savePartUserPermission" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="管理人员权限设置详细" v-model:visible="managerPermissionDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="objnameDropdownmanager">对象名称</label>
                    <Dropdown id="objnameDropdownmanager" v-model="managerPermissionObj.objname"
                        :options="allSchemaName" @change="managerPermissionObjDropchange" optionLabel="name"
                        optionValue="code" showClear="true" :filter="true" />
                </div>
                <div class="field col">
                    <label for="managerDropdown2">用户名</label>
                    <CalfInputDropdown v-model:selectedValue="managerPermissionObj.userid"
                        v-model:selectedTxt="managerPermissionObj.username" id="managerDropdown2" dictObjName="userdict"
                        :loadAll="true" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="managerDropdown3">下属</label>
                    <CalfMultiSelect v-model:selectedValue="managerPermissionObj.manageuser" id="managerDropdown2"
                        dictObjName="userdict" :loadAll="true" />
                </div>
                <div class="field col">
                    <label for="managerDropdown4">管理部门</label>
                    <CalfMultiSelect v-model:selectedValue="managerPermissionObj.manageorganization"
                        id="managerDropdown4" dictObjName="deptdict" :loadAll="true" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="canqueryDropdownmanager">是否可见</label>
                    <Dropdown id='canqueryDropdownmanager' v-model="managerPermissionObj.canquery" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
                <div class="field col">
                    <label for="canmutationDropdownmanager">是否可编辑</label>
                    <Dropdown id='canmutationDropdownmanager' v-model="managerPermissionObj.canmutation"
                        :options="boolDict" optionLabel="name" optionValue="code" placeholder="" />
                </div>
            </div>
            <h5>属性信息</h5>
            <div class="field">
                <TreeTable :key="key" :filters="filters4" filterMode="strict" :value="managerPermissionObj.fields"
                    showGridlines :scrollable="false" scrollDirection="both" @contextmenu="managerPermissionRightClick"
                    responsiveLayout="scroll">
                    <Column field="objname" header="属性名称" style="flex-grow:1; flex-basis:200px" :expander="true" frozen>
                        <template #filter>
                            <InputText type="text" v-model="filters4['objname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="flex-grow:1; flex-basis:200px">
                        <template #filter>
                            <InputText type="text" v-model="filters4['objcname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="canquery" header="是否可见" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters4['canquery']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canquery']" :options="boolDict" optionLabel="name"
                                optionValue="code" placeholder="" />
                        </template>
                    </Column>
                    <Column field="canmutation" header="是否可编辑" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters4['canmutation']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canmutation']" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" />
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeManagerPermissionDialog" />
                <Button label="刷新" icon="pi pi-refresh"
                    @click="loadSchemaField(managerPermissionObj.objname, 'managerPermission')" />
                <Button label="保存" icon="pi pi-check" @click="saveManagerPermission" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="流程权限设置详细" v-model:visible="flowPermissionDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="objnameDropdownflow">对象名称</label>
                    <Dropdown id="objnameDropdownflow" v-model="flowPermissionObj.objname" :options="allSchemaName"
                        @change="flowPermissionObjDropchange" optionLabel="name" optionValue="code" showClear="true"
                        :filter="true" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="flowDropdown2">流程</label>
                    <Dropdown id="flowDropdown2" v-model="flowPermissionObj.flowid" :options="flowOptions"
                        optionLabel="name" optionValue="code" showClear="true" @change="flowDropdownchange"
                        :filter="true" />
                </div>
                <div class="field col">
                    <label for="flowDropdown4">节点</label>
                    <Dropdown id="flowDropdown4" v-model="flowPermissionObj.stateid" :options="linkFlowStateOptions"
                        optionLabel="name" optionValue="code" showClear="true" :filter="true" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="canqueryDropdownflow">是否可见</label>
                    <Dropdown id='canqueryDropdownflow' v-model="flowPermissionObj.canquery" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
                <div class="field col">
                    <label for="canmutationDropdownflow">是否可编辑</label>
                    <Dropdown id='canmutationDropdownflow' v-model="flowPermissionObj.canmutation" :options="boolDict"
                        optionLabel="name" optionValue="code" placeholder="" />
                </div>
            </div>
            <h5>属性信息</h5>
            <div class="field">
                <TreeTable :key="key" :filters="filters5" filterMode="strict" :value="flowPermissionObj.fields"
                    showGridlines :scrollable="false" scrollDirection="both" @contextmenu="flowPermissionRightClick"
                    responsiveLayout="scroll">
                    <Column field="objname" header="属性名称" style="flex-grow:1; flex-basis:200px" :expander="true" frozen>
                        <template #filter>
                            <InputText type="text" v-model="filters5['objname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="objcname" header="描述" style="flex-grow:1; flex-basis:200px">
                        <template #filter>
                            <InputText type="text" v-model="filters5['objcname']" class="p-column-filter"
                                placeholder="" />
                        </template>
                    </Column>
                    <Column field="canquery" header="是否可见" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters5['canquery']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canquery']" :options="boolDict" optionLabel="name"
                                optionValue="code" placeholder="" />
                        </template>
                    </Column>
                    <Column field="canmutation" header="是否可编辑" style="flex-grow:1; flex-basis:100px">
                        <template #filter>
                            <Dropdown v-model="filters5['canmutation']" :showClear="true" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <Dropdown v-model="slotProps.node.data['canmutation']" :options="boolDict"
                                optionLabel="name" optionValue="code" placeholder="" />
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeFlowPermissionDialog" />
                <Button label="刷新" icon="pi pi-refresh"
                    @click="loadSchemaField(flowPermissionObj.objname, 'flowPermission')" />
                <Button label="保存" icon="pi pi-check" @click="saveFlowPermission" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <ContextMenu ref="menu" :model="items">
        <template #item="{item}">
            <Button :label="item.label" @click="contextMenuItemClick(item.flag)" class="p-button-link" />
        </template>
    </ContextMenu>
</template>
<style scoped lang="scss">
    .p-filter-column {

        .p-multiselect,
        .p-dropdown,
        .p-inputtext {
            width: 100%;
        }
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from '../../components/Message';
    import CalfInputDropdown from '../../components/CalfInputDropdown.vue';
    import CalfMultiSelect from '../../components/CalfMultiSelect.vue';
    export default {
        setup() {
            const confirm = useConfirm();
            const menu = ref();
            const items = ref([{
                flag: 1,
                label: '全部可见',
                icon: '',
            }, {
                separator: true
            }, {
                flag: 2,
                label: '全部可编辑',
                icon: '',
            }, {
                separator: true
            }, {
                flag: 3,
                label: '全部不可见',
                icon: '',
            }, {
                separator: true
            }, {
                flag: 4,
                label: '全部不可编辑',
                icon: '',
            }]);

            return {
                menu,
                items,
                confirm,
            }
        },
        data() {
            return {
                key: 0,
                boolDict: [{
                    name: '是',
                    code: '1'
                }, {
                    name: '否',
                    code: '0'
                }],
                filters1: ref({}),
                filters2: ref({}),
                filters3: ref({}),
                filters4: ref({}),
                filters5: ref({}),
                activeIndex: 0,
                loading: false,
                butLoading: false,
                currentContextMenu: '',
                selectedRowData: ref(),
                allUserPermissionDialog: false,
                appIdPermissionDialog: false,
                partUserPermissionDialog: false,
                managerPermissionDialog: false,
                flowPermissionDialog: false,
                allUserPermission: ref(),
                appIdPermission: ref(),
                partUserPermission: ref(),
                managerPermission: ref(),
                flowPermission: ref(),
                allUserPermissionSelectedKey: ref(),
                appIdPermissionSelectedKey: ref(),
                partUserPermissionSelectedKey: ref(),
                managerPermissionSelectedKey: ref(),
                flowPermissionSelectedKey: ref(),
                allAppId: [],
                allSchemaName: [],
                flowOptions: [],
                flowStateOptions: [],
                linkFlowStateOptions: [],
                objnameSearch: '',
                selectedAppId: ref(),
                selectedPartUserId: ref(),
                selectedPartUserName: ref(),
                selectedManagerUserId: ref(),
                selectedManagerUserName: ref(),
                selectedManager: ref(),
                selectedFlow: ref(),
                selectedFlowState: ref(),
                totalRecords: 0,
                first: 0,
                allUserPermissionObj: {},
                appIdPermissionObj: {},
                partUserPermissionObj: {},
                managerPermissionObj: {},
                flowPermissionObj: {},
                sHeight: '500px',
                allUserPermissionPageindex: 1,
                appIdPermissionPageindex: 1,
                partUserPermissionPageindex: 1,
                managerPermissionPageindex: 1,
                flowPermissionPageindex: 1,
            }
        },
        created() {
            MessageTip.initMsg();
            this.sHeight = (window.innerHeight - 480) + "px";
            this.loadDict();
            this.loadAllUserPermissionList(1);
        },
        methods: {
            clearFilters() {
                this.filters1 = ref({});
                this.filters2 = ref({});
                this.filters3 = ref({});
                this.filters4 = ref({});
                this.filters5 = ref({});
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'Categories',
                        value: 'appid',
                        operation: ''
                    }, {
                        name: 'Categories',
                        value: 'allschemaname',
                        operation: ''
                    }, {
                        name: 'Categories',
                        value: 'flow',
                        operation: ''
                    }, {
                        name: 'Categories',
                        value: 'flowstate',
                        operation: ''
                    }]
                };
                var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                    '){name details {name code other}}}';
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        console.log(dataObj);
                        for (var k = 0; k < dataObj.dictionarylist.length; k++) {
                            if (dataObj.dictionarylist[k].name == 'appid') {
                                this.allAppId = dataObj.dictionarylist[k].details;
                            }
                            if (dataObj.dictionarylist[k].name == 'allschemaname') {
                                this.allSchemaName = dataObj.dictionarylist[k].details;
                            }
                            if (dataObj.dictionarylist[k].name == 'flow') {
                                this.flowOptions = dataObj.dictionarylist[k].details;
                            }
                            if (dataObj.dictionarylist[k].name == 'flowstate') {
                                this.flowStateOptions = dataObj.dictionarylist[k].details;
                            }
                        }
                    } else {
                        MessageTip.warnmsg('字典数据加载失败');
                    }
                });
            },
            loadSchemaField(v_schemaname, v_callBackType) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'id',
                        value: v_schemaname,
                        operation: ''
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{permissionsetschemastructurelist(where:' + JSON.stringify(listwhere) +
                    '){id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation}}} } } } }'
                ).then(res => {
                    if (res.errcode == "0") {
                        var moduleObj = JSON.parse(unescape(Base64.decode(res.data))).data
                            .permissionsetschemastructurelist;
                        if (v_callBackType == 'allUserPermission' && moduleObj.length > 0) {
                            this.loadAllUserPermissionField(moduleObj[0].children);
                        }
                        if (v_callBackType == 'appIdPermission' && moduleObj.length > 0) {
                            this.loadAppIdPermissionField(moduleObj[0].children);
                        }
                        if (v_callBackType == 'partUserPermission' && moduleObj.length > 0) {
                            this.loadPartUserPermissionField(moduleObj[0].children);
                        }
                        if (v_callBackType == 'managerPermission' && moduleObj.length > 0) {
                            this.loadManagerPermissionField(moduleObj[0].children);
                        }
                        if (v_callBackType == 'flowPermission' && moduleObj.length > 0) {
                            this.loadFlowPermissionField(moduleObj[0].children);
                        }
                    } else {
                        MessageTip.warnmsg('类型加载失败');
                    }
                });
            },
            allUserPermissionObjDropchange(v_event) {
                this.loadSchemaField(v_event.value, 'allUserPermission');
            },
            appIdPermissionObjDropchange(v_event) {
                this.loadSchemaField(v_event.value, 'appIdPermission');
            },
            partUserPermissionObjDropchange(v_event) {
                this.loadSchemaField(v_event.value, 'partUserPermission');
            },
            managerPermissionObjDropchange(v_event) {
                this.loadSchemaField(v_event.value, 'managerPermission');
            },
            flowPermissionObjDropchange(v_event) {
                this.loadSchemaField(v_event.value, 'flowPermission');
            },
            loadAllUserPermissionField(v_moduleObj) {
                console.log('loadAllUserPermissionField');
                console.log(v_moduleObj);
                console.log(this.allUserPermissionObj.fields);
                if (this.allUserPermissionObj.fields.length > 0) {
                    this.allUserPermissionObj.fields = this.copyFieldsObj(this.allUserPermissionObj.fields,
                        v_moduleObj);
                } else {
                    this.allUserPermissionObj.fields = v_moduleObj;
                }
            },
            createallUserPermission() {
                this.allUserPermissionObj = {
                    objname: '',
                    canquery: '0',
                    canmutation: '0',
                    fields: []
                };
                this.allUserPermissionDialog = true;
                this.clearFilters();
            },
            openAllUserPermissiondetail(v_node) {
                this.allUserPermissionObj = {
                    objname: v_node.data['objname'],
                    canquery: v_node.data['canquery'],
                    canmutation: v_node.data['canmutation'],
                    fields: v_node.children
                };
                this.loadSchemaField(v_node.data['objname'], 'allUserPermission');
                this.allUserPermissionDialog = true;
                this.clearFilters();
            },
            loadAllUserPermissionList(v_page) {
                this.allUserPermissionPageindex = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'objname',
                        value: this.objnameSearch,
                        operation: ''
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{alluserpermissionsetlist(where:' + JSON.stringify(listwhere) +
                    '){id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation} children {id key data {objname objcname objtype canquery canmutation}}} } }}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.allUserPermission = jsonData.data['alluserpermissionsetlist'];
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            delallUserPermission() {
                console.log(this.allUserPermissionSelectedKey);
                var delObj = null;
                if (this.allUserPermissionSelectedKey) {
                    var delKey = '';
                    for (var key in this.allUserPermissionSelectedKey) {
                        delKey = key;
                        break;
                    }
                    for (var n = 0; n < this.allUserPermission.length; n++) {
                        if (this.allUserPermission[n]['key'] == delKey) {
                            delObj = {
                                id: this.allUserPermission[n]['id'],
                                key: this.allUserPermission[n]['key'],
                                data: this.allUserPermission[n]['data'],
                            };
                            break;
                        }
                    }
                }
                if (delObj) {
                    this.loading = true;
                    var id = delObj['id'];
                    var title = delObj['data']['objcname'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{alluserpermissionset(o:' + JSON.stringify(delObj) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.allUserPermission.length; i++) {
                                        if (this.allUserPermission[i]['id'] == id) {
                                            this.allUserPermission.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                } else {
                    MessageTip.warnmsg('请选择要删除的数据');
                }
            },
            allUserPermissionListPage(event) {
                console.log(event);
                var page = event.page;
                this.loadAllUserPermissionList(page + 1);
            },
            appIdPermissionListPage(event) {
                console.log(event);
                var page = event.page;
                this.loadAppIdPermissionList(page + 1);
            },
            partUserPermissionListPage(event) {
                console.log(event);
                var page = event.page;
                this.loadPartUserPermissionList(page + 1);
            },
            managerPermissionListPage(event) {
                console.log(event);
                var page = event.page;
                this.loadManagerPermissionList(page + 1);
            },
            flowPermissionListPage(event) {
                console.log(event);
                var page = event.page;
                this.loadFlowPermissionList(page + 1);
            },
            tabViewClick() {
                this.key += 1
                if (this.activeIndex == 0) {
                    this.totalRecords = 0;
                    this.first = 0;
                    this.loadAllUserPermissionList(1);
                }
                if (this.activeIndex == 1) {
                    this.totalRecords = 0;
                    this.first = 0;
                    this.loadAppIdPermissionList(1);
                }
                if (this.activeIndex == 2) {
                    this.totalRecords = 0;
                    this.first = 0;
                    this.loadPartUserPermissionList(1);
                }
                if (this.activeIndex == 3) {
                    this.totalRecords = 0;
                    this.first = 0;
                    this.loadManagerPermissionList(1);
                }
                if (this.activeIndex == 4) {
                    this.totalRecords = 0;
                    this.first = 0;
                    this.loadFlowPermissionList(1);
                }
            },
            formatData(v_type, value) {
                if (v_type == 'bool') {
                    if (value == '1') {
                        return '是';
                    } else {
                        return '否';
                    }
                }
                return value;
            },
            closeAllUserPermissionDialog() {
                this.allUserPermissionDialog = false;
            },
            saveAllUserPermission() {
                this.butLoading = true;
                var saveObj = {
                    id: this.allUserPermissionObj.objname,
                    key: '',
                    data: {
                        objname: this.allUserPermissionObj.objname,
                        objcname: '',
                        objtype: 'obj',
                        canquery: this.allUserPermissionObj.canquery,
                        canmutation: this.allUserPermissionObj.canmutation,
                    },
                    children: this.allUserPermissionObj.fields,
                };
                var graphql = `mutation{alluserpermissionset(o:` +
                    JSON.stringify(saveObj) + `){id}}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.butLoading = false;
                        this.loadAllUserPermissionList(this.allUserPermissionPageindex);
                        this.closeAllUserPermissionDialog();
                    } else {
                        this.butLoading = false;
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            contextMenuItemClick(v_flag) {
                var permissionData = null;
                if (this.currentContextMenu == 'allUserPermission') {
                    permissionData = this.allUserPermissionObj.fields;
                }
                if (this.currentContextMenu == 'appIdPermission') {
                    permissionData = this.appIdPermissionObj.fields;
                }
                if (this.currentContextMenu == 'partUserPermission') {
                    permissionData = this.partUserPermissionObj.fields;
                }
                if (this.currentContextMenu == 'managerPermission') {
                    permissionData = this.managerPermissionObj.fields;
                }
                if (this.currentContextMenu == 'flowPermission') {
                    permissionData = this.flowPermissionObj.fields;
                }
                if (permissionData && v_flag) {
                    console.log(v_flag);
                    for (var i = 0; i < permissionData.length; i++) {
                        if (v_flag == 1) {
                            permissionData[i].data.canquery = '1';
                        }
                        if (v_flag == 2) {
                            permissionData[i].data.canmutation = '1';
                        }
                        if (v_flag == 3) {
                            permissionData[i].data.canquery = '0';
                        }
                        if (v_flag == 4) {
                            permissionData[i].data.canmutation = '0';
                        }
                        if (permissionData[i].children && permissionData[i].children.length > 0) {
                            this.setPermissionDataBycontextMenuItem(permissionData[i].children, v_flag);
                        }
                    }
                }
                this.$refs.menu.hide();
            },
            setPermissionDataBycontextMenuItem(v_data, v_flag) {
                for (var i = 0; i < v_data.length; i++) {
                    if (v_flag == 1) {
                        v_data[i].data.canquery = '1';
                    }
                    if (v_flag == 2) {
                        v_data[i].data.canmutation = '1';
                    }
                    if (v_flag == 3) {
                        v_data[i].data.canquery = '0';
                    }
                    if (v_flag == 4) {
                        v_data[i].data.canmutation = '0';
                    }
                    if (v_data[i].children && v_data[i].children.length > 0) {
                        this.setPermissionDataBycontextMenuItem(v_data[i].children, v_flag);
                    }
                }
            },
            allUserPermissionRightClick(event) {
                this.currentContextMenu = 'allUserPermission';
                this.$refs.menu.show(event);
            },
            loadAppIdPermissionList(v_page) {
                this.appIdPermissionPageindex = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'appid',
                        value: this.selectedAppId,
                        operation: ''
                    }, {
                        name: 'objname',
                        value: this.objnameSearch,
                        operation: ''
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{appidpermissionsetlist(where:' + JSON.stringify(listwhere) +
                    '){id key data {appid appidcname objname objcname objtype canquery canmutation} children {id key data {appid appidcname objname objcname objtype canquery canmutation} children {id key data {appid appidcname objname objcname objtype canquery canmutation} children {id key data {appid appidcname objname objcname objtype canquery canmutation} children {id key data {appid appidcname objname objcname objtype canquery canmutation}}} } }}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        console.log('loadAppIdPermissionList...');
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.appIdPermission = jsonData.data['appidpermissionsetlist'];
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                        console.log(this.appIdPermission);
                    }
                });
            },
            closeAppIdPermissionDialog() {
                this.appIdPermissionDialog = false;
            },
            createappIdPermission() {
                this.appIdPermissionObj = {
                    appid: '',
                    appidcname: '',
                    objname: '',
                    canquery: '0',
                    canmutation: '0',
                    fields: []
                };
                this.appIdPermissionDialog = true;
                this.clearFilters();
            },
            delappIdPermission() {
                console.log(this.appIdPermissionSelectedKey);
                var delObj = null;
                if (this.appIdPermissionSelectedKey) {
                    for (var key in this.appIdPermissionSelectedKey) {
                        for (var n = 0; n < this.appIdPermission.length; n++) {
                            if (this.appIdPermission[n]['key'] == key) {
                                delObj = {
                                    id: this.appIdPermission[n]['id'],
                                    key: this.appIdPermission[n]['key'],
                                    data: this.appIdPermission[n]['data'],
                                };
                                break;
                            }
                        }
                    }
                }
                if (delObj) {
                    this.loading = true;
                    var id = delObj['id'];
                    var title = delObj['data']['appidcname'] + '-' + delObj['data']['objcname'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{appidpermissionset(o:' + JSON.stringify(delObj) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.appIdPermission.length; i++) {
                                        if (this.appIdPermission[i]['id'] == id) {
                                            this.appIdPermission.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                } else {
                    MessageTip.warnmsg('请选择要删除的数据');
                }
            },
            openAppIdPermissiondetail(v_node) {
                this.appIdPermissionObj = {
                    appid: v_node.data['appid'],
                    appidcname: v_node.data['appidcname'],
                    objname: v_node.data['objname'],
                    canquery: v_node.data['canquery'],
                    canmutation: v_node.data['canmutation'],
                    fields: v_node.children
                };
                console.log('openAppIdPermissiondetail....');
                console.log(this.appIdPermissionObj);
                this.loadSchemaField(v_node.data['objname'], 'appIdPermission');
                this.appIdPermissionDialog = true;
                this.clearFilters();
            },
            saveAppIdPermission() {
                this.butLoading = true;
                var saveObj = {
                    id: this.appIdPermissionObj.objname,
                    key: '',
                    data: {
                        appid: this.appIdPermissionObj.appid,
                        appidcname: '',
                        objname: this.appIdPermissionObj.objname,
                        objcname: '',
                        objtype: 'obj',
                        canquery: this.appIdPermissionObj.canquery,
                        canmutation: this.appIdPermissionObj.canmutation,
                    },
                    children: this.appIdPermissionObj.fields,
                };
                var graphql = `mutation{appidpermissionset(o:` +
                    JSON.stringify(saveObj) + `){id}}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.butLoading = false;
                        this.loadAppIdPermissionList(this.appIdPermissionPageindex);
                        this.closeAppIdPermissionDialog();
                    } else {
                        this.butLoading = false;
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            loadAppIdPermissionField(v_moduleObj) {
                console.log('loadAppIdPermissionField');
                if (this.appIdPermissionObj.fields.length > 0) {
                    this.appIdPermissionObj.fields = this.copyFieldsObj(this.appIdPermissionObj.fields,
                        v_moduleObj);
                    console.log(v_moduleObj);
                    console.log(this.appIdPermissionObj);
                    console.log('1');
                } else {
                    this.appIdPermissionObj.fields = v_moduleObj;
                    console.log('2');
                }
                console.log(this.appIdPermissionObj.fields);
            },
            appIdPermissionRightClick(event) {
                this.currentContextMenu = 'appIdPermission';
                this.$refs.menu.show(event);
            },
            loadPartUserPermissionList(v_page) {
                this.partUserPermissionPageindex = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'userid',
                        value: this.selectedPartUserId,
                        operation: ''
                    }, {
                        name: 'objname',
                        value: this.objnameSearch,
                        operation: ''
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{partuserpermissionsetlist(where:' + JSON.stringify(listwhere) +
                    '){id key data {userid username objname objcname objtype canquery canmutation} children {id key data {userid username objname objcname objtype canquery canmutation} children {id key data {userid username objname objcname objtype canquery canmutation} children {id key data {userid username objname objcname objtype canquery canmutation} children {id key data {userid username objname objcname objtype canquery canmutation}}} } }}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.partUserPermission = jsonData.data['partuserpermissionsetlist'];
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            partUserPermissionRightClick(event) {
                this.currentContextMenu = 'partUserPermission';
                this.$refs.menu.show(event);
            },
            closePartUserPermissionDialog() {
                this.partUserPermissionDialog = false;
            },
            createpartUserPermission() {
                this.partUserPermissionObj = {
                    userid: '',
                    username: '',
                    objname: '',
                    canquery: '0',
                    canmutation: '0',
                    fields: []
                };
                this.partUserPermissionDialog = true;
                this.clearFilters();
            },
            delpartUserPermission() {
                console.log(this.partUserPermissionSelectedKey);
                var delObj = null;
                if (this.partUserPermissionSelectedKey) {
                    for (var key in this.partUserPermissionSelectedKey) {
                        for (var n = 0; n < this.partUserPermission.length; n++) {
                            if (this.partUserPermission[n]['key'] == key) {
                                delObj = {
                                    id: this.partUserPermission[n]['id'],
                                    key: this.partUserPermission[n]['key'],
                                    data: this.partUserPermission[n]['data'],
                                };
                                break;
                            }
                        }
                    }
                }
                if (delObj) {
                    this.loading = true;
                    var id = delObj['id'];
                    var title = delObj['data']['username'] + '-' + delObj['data']['objcname'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{partuserpermissionset(o:' + JSON.stringify(delObj) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.partUserPermission.length; i++) {
                                        if (this.partUserPermission[i]['id'] == id) {
                                            this.partUserPermission.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                } else {
                    MessageTip.warnmsg('请选择要删除的数据');
                }
            },
            openPartUserPermissiondetail(v_node) {
                this.partUserPermissionObj = {
                    userid: v_node.data['userid'],
                    username: v_node.data['username'],
                    objname: v_node.data['objname'],
                    canquery: v_node.data['canquery'],
                    canmutation: v_node.data['canmutation'],
                    fields: v_node.children
                };
                this.loadSchemaField(v_node.data['objname'], 'partUserPermission');
                this.partUserPermissionDialog = true;
                this.clearFilters();
            },
            savePartUserPermission() {
                this.butLoading = true;
                var saveObj = {
                    id: this.partUserPermissionObj.objname,
                    key: '',
                    data: {
                        userid: this.partUserPermissionObj.userid,
                        username: this.partUserPermissionObj.username,
                        objname: this.partUserPermissionObj.objname,
                        objcname: '',
                        objtype: 'obj',
                        canquery: this.partUserPermissionObj.canquery,
                        canmutation: this.partUserPermissionObj.canmutation,
                    },
                    children: this.partUserPermissionObj.fields,
                };
                var graphql = `mutation{partuserpermissionset(o:` +
                    JSON.stringify(saveObj) + `){id}}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.butLoading = false;
                        this.loadPartUserPermissionList(this.partUserPermissionPageindex);
                        this.closePartUserPermissionDialog();
                    } else {
                        this.butLoading = false;
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            loadPartUserPermissionField(v_moduleObj) {
                console.log('loadPartUserPermissionField');
                if (this.partUserPermissionObj.fields.length > 0) {
                    this.partUserPermissionObj.fields = this.copyFieldsObj(this.partUserPermissionObj.fields,
                        v_moduleObj);
                } else {
                    this.partUserPermissionObj.fields = v_moduleObj;
                }
            },
            loadManagerPermissionList(v_page) {
                this.managerPermissionPageindex = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'userid',
                        value: this.selectedManagerUserId,
                        operation: ''
                    }, {
                        name: 'objname',
                        value: this.objnameSearch,
                        operation: ''
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{managerpermissionsetlist(where:' + JSON.stringify(listwhere) +
                    '){id key data {userid username manageuser {name code} manageorganization {name code} objname objcname objtype canquery canmutation} children {id key data {userid username manageuser {name code} manageorganization {name code} objname objcname objtype canquery canmutation} children {id key data {userid username manageuser {name code} manageorganization {name code} objname objcname objtype canquery canmutation} children {id key data {userid username manageuser {name code} manageorganization {name code} objname objcname objtype canquery canmutation} children {id key data {userid username manageuser {name code} manageorganization {name code} objname objcname objtype canquery canmutation}}} } }}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.managerPermission = jsonData.data['managerpermissionsetlist'];
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            managerPermissionRightClick(event) {
                this.currentContextMenu = 'managerPermission';
                this.$refs.menu.show(event);
            },
            closeManagerPermissionDialog() {
                this.managerPermissionDialog = false;
            },
            createManagerPermission() {
                this.managerPermissionObj = {
                    userid: '',
                    username: '',
                    objname: '',
                    canquery: '0',
                    canmutation: '0',
                    manageuser: [],
                    manageorganization: [],
                    fields: []
                };
                this.managerPermissionDialog = true;
                this.clearFilters();
            },
            delManagerPermission() {
                console.log(this.managerPermissionSelectedKey);
                var delObj = null;
                if (this.managerPermissionSelectedKey) {
                    for (var key in this.managerPermissionSelectedKey) {
                        for (var n = 0; n < this.managerPermission.length; n++) {
                            if (this.managerPermission[n]['key'] == key) {
                                delObj = {
                                    id: this.managerPermission[n]['id'],
                                    key: this.managerPermission[n]['key'],
                                    data: this.managerPermission[n]['data'],
                                };
                                break;
                            }
                        }
                    }
                }
                if (delObj) {
                    this.loading = true;
                    var id = delObj['id'];
                    var title = delObj['data']['username'] + '-' + delObj['data']['objcname'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{managerpermissionset(o:' + JSON.stringify(delObj) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.managerPermission.length; i++) {
                                        if (this.managerPermission[i]['id'] == id) {
                                            this.managerPermission.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                } else {
                    MessageTip.warnmsg('请选择要删除的数据');
                }
            },
            openManagerPermissiondetail(v_node) {
                this.managerPermissionObj = {
                    userid: v_node.data['userid'],
                    username: v_node.data['username'],
                    objname: v_node.data['objname'],
                    canquery: v_node.data['canquery'],
                    canmutation: v_node.data['canmutation'],
                    manageuser: v_node.data['manageuser'],
                    manageorganization: v_node.data['manageorganization'],
                    fields: v_node.children
                };
                this.loadSchemaField(v_node.data['objname'], 'managerPermission');
                this.managerPermissionDialog = true;
                this.clearFilters();
            },
            saveManagerPermission() {
                this.butLoading = true;
                var manageuserlist = [];
                console.log('saveManagerPermission...');
                console.log(this.managerPermissionObj.manageuser);
                for (var i = 0; i < this.managerPermissionObj.manageuser.length; i++) {
                    var namelist = this.managerPermissionObj.manageuser[i]['name'].split('-');
                    manageuserlist.push({
                        "code": this.managerPermissionObj.manageuser[i]['code'],
                        "name": namelist[namelist.length - 1]
                    });
                }
                var saveObj = {
                    id: this.managerPermissionObj.objname,
                    key: '',
                    data: {
                        userid: this.managerPermissionObj.userid,
                        username: this.managerPermissionObj.username,
                        objname: this.managerPermissionObj.objname,
                        objcname: '',
                        objtype: 'obj',
                        canquery: this.managerPermissionObj.canquery,
                        canmutation: this.managerPermissionObj.canmutation,
                        manageuser: manageuserlist,
                        manageorganization: this.managerPermissionObj.manageorganization,
                    },
                    children: this.managerPermissionObj.fields,
                };
                var graphql = `mutation{managerpermissionset(o:` +
                    JSON.stringify(saveObj) + `){id}}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.butLoading = false;
                        this.loadManagerPermissionList(this.managerPermissionPageindex);
                        this.closeManagerPermissionDialog();
                    } else {
                        this.butLoading = false;
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            loadManagerPermissionField(v_moduleObj) {
                console.log('loadManagerPermissionField');
                if (this.managerPermissionObj.fields.length > 0) {
                    this.managerPermissionObj.fields = this.copyFieldsObj(this.managerPermissionObj.fields,
                        v_moduleObj);
                } else {
                    this.managerPermissionObj.fields = v_moduleObj;
                }
            },
            loadFlowPermissionList(v_page) {
                this.flowPermissionPageindex = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'flowidsearch',
                        value: this.selectedFlow,
                        operation: ''
                    }, {
                        name: 'stateidsearch',
                        value: this.selectedFlowState,
                        operation: ''
                    }, {
                        name: 'objname',
                        value: this.objnameSearch,
                        operation: ''
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{flowpermissionsetlist(where:' + JSON.stringify(listwhere) +
                    '){id key data {flowid flowname stateid statename objname objcname objtype canquery canmutation} children {id key data {flowid flowname stateid statename objname objcname objtype canquery canmutation} children {id key data {flowid flowname stateid statename objname objcname objtype canquery canmutation} children {id key data {flowid flowname stateid statename objname objcname objtype canquery canmutation} children {id key data {flowid flowname stateid statename objname objcname objtype canquery canmutation}}} } }}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.flowPermission = jsonData.data['flowpermissionsetlist'];
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            flowPermissionRightClick(event) {
                this.currentContextMenu = 'flowPermission';
                this.$refs.menu.show(event);
            },
            closeFlowPermissionDialog() {
                this.flowPermissionDialog = false;
            },
            createFlowPermission() {
                this.flowPermissionObj = {
                    flowid: '',
                    flowname: '',
                    stateid: '',
                    statename: '',
                    objname: '',
                    canquery: '0',
                    canmutation: '0',
                    fields: []
                };
                this.flowPermissionDialog = true;
                this.clearFilters();
            },
            delFlowPermission() {
                console.log(this.flowPermissionSelectedKey);
                var delObj = null;
                if (this.flowPermissionSelectedKey) {
                    for (var key in this.flowPermissionSelectedKey) {
                        for (var n = 0; n < this.flowPermission.length; n++) {
                            if (this.flowPermission[n]['key'] == key) {
                                delObj = {
                                    id: this.flowPermission[n]['id'],
                                    key: this.flowPermission[n]['key'],
                                    data: this.flowPermission[n]['data'],
                                };
                                break;
                            }
                        }
                    }
                }
                if (delObj) {
                    this.loading = true;
                    var id = delObj['id'];
                    var title = delObj['data']['flowname'] + '-' + delObj['data']['statename'] + '-' + delObj['data'][
                        'objcname'
                    ];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{flowpermissionset(o:' + JSON.stringify(delObj) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.flowPermission.length; i++) {
                                        if (this.flowPermission[i]['id'] == id) {
                                            this.flowPermission.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                } else {
                    MessageTip.warnmsg('请选择要删除的数据');
                }
            },
            openFlowPermissiondetail(v_node) {
                this.flowPermissionObj = {
                    flowid: v_node.data['flowid'],
                    flowname: v_node.data['flowname'],
                    stateid: v_node.data['stateid'],
                    statename: v_node.data['statename'],
                    objname: v_node.data['objname'],
                    canquery: v_node.data['canquery'],
                    canmutation: v_node.data['canmutation'],
                    fields: v_node.children
                };
                this.flowDropdownchange({
                    value: v_node.data['flowid']
                });
                this.loadSchemaField(v_node.data['objname'], 'flowPermission');
                this.flowPermissionDialog = true;
                this.clearFilters();
            },
            saveFlowPermission() {
                this.butLoading = true;
                var saveObj = {
                    id: this.flowPermissionObj.objname,
                    key: '',
                    data: {
                        flowid: this.flowPermissionObj.flowid,
                        flowname: this.flowPermissionObj.flowname,
                        stateid: this.flowPermissionObj.stateid,
                        statename: this.flowPermissionObj.statename,
                        objname: this.flowPermissionObj.objname,
                        objcname: '',
                        objtype: 'obj',
                        canquery: this.flowPermissionObj.canquery,
                        canmutation: this.flowPermissionObj.canmutation,
                    },
                    children: this.flowPermissionObj.fields,
                };
                var graphql = `mutation{flowpermissionset(o:` +
                    JSON.stringify(saveObj) + `){id}}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.butLoading = false;
                        this.loadFlowPermissionList(this.flowPermissionPageindex);
                        this.closeFlowPermissionDialog();
                    } else {
                        this.butLoading = false;
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            loadFlowPermissionField(v_moduleObj) {
                console.log('loadFlowPermissionField');
                if (this.flowPermissionObj.fields.length > 0) {
                    this.flowPermissionObj.fields = this.copyFieldsObj(this.flowPermissionObj.fields, v_moduleObj);
                    console.log(v_moduleObj);
                    console.log(this.flowPermissionObj.fields);
                } else {
                    this.flowPermissionObj.fields = v_moduleObj;
                }
            },
            flowDropdownchange(v_event) {
                this.linkFlowStateOptions = [];
                for (var i = 0; i < this.flowStateOptions.length; i++) {
                    if (this.flowStateOptions[i].other == v_event.value) {
                        this.linkFlowStateOptions.push(this.flowStateOptions[i]);
                    }
                }
            },
            copyFieldsObj(v_target, v_source) {
                if (v_target && v_source) {
                    for (var i = 0; i < v_target.length; i++) {
                        for (var j = 0; j < v_source.length; j++) {
                            if (v_target[i]['id'] == v_source[j]['id']) {
                                v_source[j]['data'] = v_target[i]['data'];
                                if (v_source[j]['children'] && v_source[j]['children'].length > 0) {
                                    v_source[j]['children'] = this.copyFieldsObj(v_target[i]['children'], v_source[j][
                                        'children'
                                    ]);
                                }
                                break;
                            }
                        }
                    }
                }
                return v_source;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            CalfInputDropdown,
            CalfMultiSelect,
        }
    }
</script>